import React, { Suspense } from 'react'
import Loader from '../../adapters/helpers/Loader';

function ConsentBlock(props) {
    const activateCookieConsentEU = props?.document?.fields?.activateCookieConsentEU;
    const cookieConsentLabel = props?.document?.fields?.cookieConsentLabel?.fields?.text;
    const activateDoNotSellMyData = props?.document?.fields?.activateDoNotSellMyData;
    const doNotSellMyDataLabel = props?.document?.fields?.doNotSellMyDataLabel?.fields?.text;

    return (
        <>
        <Suspense fallback={<Loader/>}>
            {(activateCookieConsentEU && cookieConsentLabel) &&
                <span className='ob-eu-cookie-consent' dangerouslySetInnerHTML={{
                    __html: `
                <button aria-label="${cookieConsentLabel}" onclick="Optanon.ToggleInfoDisplay();">${cookieConsentLabel}</button>
                `}}>
                </span>
            }
            {(doNotSellMyDataLabel && activateDoNotSellMyData) &&
                <span className='ob-us-personal-data' dangerouslySetInnerHTML={{
                    __html: `
                        <button aria-label="${doNotSellMyDataLabel}" onclick="Optanon.ToggleInfoDisplay();">${doNotSellMyDataLabel}</button>
                        `}}>
                </span>
            }
            </Suspense>
        </>
    )
}

export default ConsentBlock;
